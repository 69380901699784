@import "../../../global.scss";
.leftFilter{
    width: calc(100%);
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-right: 1px #333 solid;
    min-height: 100vh;
    background-color: #343444;
    border-radius: 10px;
    @include mobile_potrait{
        min-height: auto;
        width: 90%;
    }
    .searchFilter{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 1rem;
        .searchHeader{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
            h5{
                margin: 0;
                font-size: 1rem;
                margin-bottom: 0.5rem;
            }
        }
        .search {
            color: #ffffff;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            span {
                margin-bottom: 0.5rem;
                padding: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                input {
                    font-size: 14px;
                    border: 1px #aaa solid;
                    border-radius: 7px;
                }
                button{
                    position: absolute;
                    right: 0;
                    border: none;
                    background-color: #ffffff00;
                    color: #fff;

                }
            }
                
        }
    }
    .filterHeader{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        border-bottom: #333 1px solid;
        padding-bottom: 1rem;
        h6{
            margin: 0;
            font-size: 1.5rem;
        }
        h5{
            margin: 0;
            font-size: 1rem;

        }
        button{
            background-color: #ffffff00;
            border: none;
            color: #5142fc;
            transition: all 0.3s ease;
            cursor: pointer;
            &:hover{
                color: #fff;

            }
        }
    }
    .hover{
        cursor: pointer;
    }
    .filterForm{
        color: #ffffff;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        span {
            margin-bottom: 1rem;
            padding: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            input[type="checkbox"] {
                visibility: hidden;
                display: none;
            }
            
            label {
                cursor: pointer;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                font-size: 16px;
                &::after{
                    margin: 0 !important;
                }
                p{
                    
                    img{
                        height: 1rem;
                        margin-right: 0.5rem;
                    }
                }
                
            }
            input[type="checkbox"] + label:after {
                border: 1px solid #333;
                border-radius: 3px;
                content: "\00a0";
                display: inline-block;
                font: 16px/1em sans-serif;
                height: 22px;
                margin: 0 1em 0 0;
                padding: 0;
                vertical-align: top;
                width: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
            }
            input[type="checkbox"]:checked + label:after {
                background: #5142fc;
                border-color: #5142fc;
                color: #fff;
                content: "\2713";
                text-align: center;
            }
            input[type="checkbox"]:checked + label:after {
                font-weight: bold;
            }
            
            input[type="checkbox"]:focus + label::before {
                outline: rgb(59, 153, 252) auto 5px;
            }
            
          }          
    }
    .eventFilterForm{
        color: #ffffff;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        span {
            margin-bottom: 1rem;
            padding: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            input[type="checkbox"] {
                visibility: hidden;
                display: none;
            }
            
            label {
                cursor: pointer;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                font-size: 16px;
                p{
                    
                    img{
                        height: 1rem;
                        margin-right: 0.5rem;
                    }
                }
                &::after{
                    margin: 0 !important;
                    @include mobile_potrait{
                        margin: 0 !important;
                    }
                }
            }
            input[type="checkbox"] + label:after {
                border: 1px solid #333;
                border-radius: 3px;
                content: "\00a0";
                display: inline-block;
                font: 16px/1em sans-serif;
                height: 22px;
                margin: 0 1em 0 0;
                padding: 0;
                vertical-align: top;
                width: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
            }
            input[type="checkbox"]:checked + label:after {
                background: #5142fc;
                border-color: #5142fc;
                color: #fff;
                content: "\2713";
                text-align: center;
            }
            input[type="checkbox"]:checked + label:after {
                font-weight: bold;
            }
            
            input[type="checkbox"]:focus + label::before {
                outline: rgb(59, 153, 252) auto 5px;
            }
            
          }          
    }
}
