@import "../../../global.scss";
.collectionCard {
  width: calc(calc(calc(100vw - 4rem - 350px) / 4) - 50px);
  // width: 450px;
  max-width: 450px;
  padding: 10px;
  background-color: #343444;
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 20px;
  transition: all 0.3s ease;
  box-shadow: 5px 5px 10px #00000022;

  @include mobile_potrait {
    min-width: 100%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &:hover {
    transform: translateY(-0.5rem);
    .card-media {
      .button-place-bid {
        bottom: 40%;
        opacity: 1;
      }
    }
  }

  .card-media {
    width: calc(calc(calc(100vw - 4rem - 450px) / 4) - 50px);
    max-width: 450px;
    // height: 11vw;
    height: calc(calc(calc(100vw - 4rem - 450px) / 4) - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    @include mobile_potrait {
      height: 80vw;
      width: 80vw;
    }
    .left-wrap {
      width: 100%;
      height: 100%;
      //   width: 11vw;
      //   height: 11vw;
      display: flex;
      align-items: center;
      justify-content: center;
      //   margin-right: 5px;
      .nftCollection1 {
        width: 100%;
        height: 100%;
        background-color: #d2c9c0;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .right-wrap {
      width: calc(100% - 11vw - 5px);
      // width: 3vw;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      height: 100%;
      .nftCollection2 {
        width: 100%;
        height: calc(33% - 5px);
        // width: 3vw;
        // height: 3vw;
        background-color: #d2c9c0;
        border-radius: 7px;
        overflow: hidden;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .nftCollection3 {
        width: 100%;
        height: calc(33% - 5px);
        // width: 3vw;
        // height: 3vw;
        background-color: #d2c9c0;
        border-radius: 7px;
        overflow: hidden;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .nftCollection4 {
        width: 100%;
        height: calc(33% - 10px);
        // width: 3vw;
        // height: 3vw;
        background-color: #d2c9c0;
        border-radius: 7px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
.meta-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  .author {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 10px;
      }
    }
    .info {
      width: calc(100%);
      margin-left: 10px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      a {
        text-decoration: none;
        color: #fff;
        font-size: min(18px, 1vw);
        font-weight: 600;
        @include mobile_potrait {
          font-size: 16px;
        }
      }
      span {
        // width: 100%;
        margin-bottom: 5px;
        font-size: 0.8rem;
        flex-direction: column;
        p {
          margin: 0;
          margin-bottom: 0rem;
          font-size: min(12px, 0.8vw);
          @include mobile_potrait {
            font-size: 14px;
          }
        }
        h6 {
          margin: 0;
          font-size: min(16px, 0.7vw);
          @include mobile_potrait {
            font-size: 16px;
          }
          img {
            margin-right: 0.5rem;
            height: 1rem;
          }
        }
      }
    }
  }
  .volume {
    top: 1rem;
    right: 1rem;
    border-radius: 1rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      margin-bottom: 0.3rem;
      font-size: 14px;
      font-size: min(12px, 0.6vw);
      @include mobile_potrait {
        font-size: 14px;
      }
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      img {
        margin-right: 0.5rem;
        height: 1rem;
      }
      h5 {
        margin: 0;
        font-size: 16px;
        font-size: min(14px, 0.7vw);
        @include mobile_potrait {
          font-size: 16px;
        }
      }
    }
  }
}
.meta-info1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  .author {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 10px;
      }
    }
    .info {
      width: calc(100% - 50px);
      margin-left: 10px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      a {
        text-decoration: none;
        color: #fff;
        font-size: min(18px, 1vw);
        font-weight: 600;
        @include mobile_potrait {
          font-size: 16px;
        }
      }
      span {
        margin-bottom: 5px;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        flex-direction: row;
        p {
          margin-bottom: 0rem;
          margin-right: 0.5rem;
          font-size: min(12px, 0.6vw);
          @include mobile_potrait {
            font-size: 14px;
          }
        }
        h6 {
          margin: 0;
          font-size: min(16px, 0.7vw);
          @include mobile_potrait {
            font-size: 16px;
          }
          img {
            margin-right: 0.5rem;
            height: 1rem;
          }
        }
      }
    }
  }
  .volume {
    top: 1rem;
    right: 1rem;
    border-radius: 1rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      margin-bottom: 0.3rem;
      font-size: 14px;
      font-size: min(12px, 0.6vw);
      @include mobile_potrait {
        font-size: 14px;
      }
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      img {
        margin-right: 0.5rem;
        height: 1rem;
      }
      h5 {
        margin: 0;
        font-size: 16px;
        font-size: min(14px, 0.7vw);
        @include mobile_potrait {
          font-size: 16px;
        }
      }
    }
  }
}
.collectionCard1 {
  width: calc(22.5vw - 30px);
  padding: 10px;
  background-color: #343444;
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 20px;
  transition: all 0.3s ease;
  box-shadow: 5px 5px 10px #00000022;

  @include mobile_potrait {
    min-width: 100%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &:hover {
    transform: translateY(-0.5rem);
    .card-media {
      .button-place-bid {
        bottom: 40%;
        opacity: 1;
      }
    }
  }

  .card-media {
    width: calc(22.5vw - 60px);

    height: calc(22.5vw - 60px);
    // height: 14vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    @include mobile_potrait {
      height: 80vw;
      width: 80vw;
    }
    .left-wrap {
      width: 100%;
      height: 100%;
      //   width: 14vw;
      //   height: 14vw;

      display: flex;
      align-items: center;
      justify-content: center;
      //   margin-right: 5px;
      .nftCollection1 {
        width: 100%;
        height: 100%;
        background-color: #d2c9c0;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .right-wrap {
      width: calc(100% - 14vw - 5px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      height: 100%;
      .nftCollection2 {
        width: 100%;
        height: calc(33% - 5px);
        background-color: #d2c9c0;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .nftCollection3 {
        width: 100%;
        height: calc(33% - 5px);
        background-color: #d2c9c0;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .nftCollection4 {
        width: 100%;
        height: calc(33% - 10px);
        background-color: #d2c9c0;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
