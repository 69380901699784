@import "../../../global.scss";
.explorer-leftFilter{
    width: calc(100%);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-right: 1px #333 solid;
    min-height: 100vh;
    background-color: #343444;
    border-radius: 10px;
    @include mobile_potrait{
        min-height: auto;
        width: 90%;
    }
    .filterTop{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        border-bottom: 1px solid #333;
        margin-bottom: 1rem;
        h3{
            margin: 0;
            margin-bottom: 0.5rem;
            
        }
        .clear{
            border: none;
            background-color: #ffffff00;
            color: #5142fc;
            transition: all ease 0.3s;
            cursor: pointer;
            &:hover{
                color: #fff;
            }
        }

    }
    
    .searchFilter{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
            margin-bottom: 1rem;
            h5{
                margin: 0;
            }
        }
        .search {
            color: #ffffff;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            span {
                margin-bottom: 0.5rem;
                padding: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                input {
                    font-size: 14px;
                    border: 1px #aaa solid;
                    border-radius: 7px;
                }
                button{
                    position: absolute;
                    right: 0;
                    border: none;
                    background-color: #ffffff00;
                    color: #fff;

                }
            }
                
        }
    }
    .filterHeader{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        border-bottom: 1px solid #333;
        margin-bottom: 1rem;
        h6{
            font-size: 1.5rem;
            margin: 0;
            margin-bottom: 1rem;
        }
    }
    .priceFilter{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
        }
        .search {
            color: #ffffff;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            span {
                margin-bottom: 0.5rem;
                padding: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                input {
                    font-size: 14px;
                    border: 1px #aaa solid;
                    border-radius: 7px;
                }
                p{
                    margin: 0.5rem;
                }
                button{
                    padding: 5px 30px;
                    border: 1px #fff solid;
                    background-color: #ffffff00;
                    color: #fff;
                    transition:  all ease 0.3s;
                    border-radius: 2rem;
                    margin-top: 1rem;
                    cursor: pointer;
                    &:hover{
                        color: #aaa;

                    }
                }
            }
        }
    }
    .myform{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 1;
        margin-bottom: 1rem;
        width: 100%;
        @include mobile_potrait{
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
        }
        @include mobile_landscape{
        }
        
        span{
            color: #000;
            margin-bottom: 0.5rem;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
            @include mobile_potrait{
                margin-bottom: 1rem;
                width: 40%;
                margin-left: 10%;
                padding: 0;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: row;
            }
            @include mobile_landscape{
                margin-bottom: 0rem;
            }
            label{
                color: #fff;
                width: auto;
                @include mobile_potrait{
                    font-size: 0.8rem;
                    margin-left: 1rem;
                }
                @include mobile_landscape{
                    font-size: 0.8rem;
                }
            }
        }
          
        .radio {
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            margin-right: 4px;
            &:before {
                transition : all 0.4s ease;
                transform : scale(0,0); 
                content: "\2713";
                position: absolute;
                z-index: 1;
                color: #fff;
            }
            
            &:checked {
                &:before {
                transform : scale(1,1); 
                
                }
                &::after{
                    background: #5142fc;
                }
            }
            
            &:after {
                content: "";
                position: absolute;
                top: -4px;
                left: -4px;
                transition : all 0.4s ease;
                width: 22px;
                height: 22px;
                background: #fff;
                border: 1px solid #333;
                border-radius: 3px;
            }
        }

    }
    .filterForm{
        color: #ffffff;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        span {
            margin-bottom: 1rem;
            padding: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between !important;
            flex-direction: row-reverse;
            input[type="checkbox"] {
                visibility: hidden;
                display: none;
            }
            
            label {
                cursor: pointer;
                width: 100 !important;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                font-size: 16px;
               
                &::after{
                    margin: 0 !important;
                    @include mobile_potrait{
                        margin: 0 !important;
                    }
                }
                
            }
            input[type="checkbox"] + label:after {
                border: 1px solid #333;
                border-radius: 3px;
                content: "\00a0";
                display: inline-block;
                font: 16px/1em sans-serif;
                height: 22px;
                margin: 0 1em 0 0;
                padding: 0;
                vertical-align: top;
                width: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                
            }
            input[type="checkbox"]:checked + label:after {
                background: #5142fc;
                border-color: #5142fc;
                color: #fff;
                content: "\2713";
                text-align: center;
            }
            input[type="checkbox"]:checked + label:after {
                font-weight: bold;
            }
            
            input[type="checkbox"]:focus + label::before {
                outline: rgb(59, 153, 252) auto 5px;
            }
            
          }

          
    }
    .filterForm1{
        color: #ffffff;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        span {
            margin-bottom: 1rem;
            padding: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between !important;
            flex-direction: row-reverse;
            input[type="checkbox"] {
                visibility: hidden;
                display: none;
            }
            p{
                margin: 0;
            }
            label {
                cursor: pointer;
                width: auto !important;
                display: flex;
                align-items: center;
                justify-content: center !important;
                flex-direction: row-reverse !important;
                font-size: 16px;
                p{
                    
                    img{
                        height: 1rem;
                        margin-right: 0.5rem;
                    }
                }
                &::after{
                    margin: 0 !important;
                    margin-right: 1rem !important;
                    @include mobile_potrait{
                        margin: 0 !important;
                    }
                }
                
            }
            input[type="checkbox"] + label:after {
                border: 1px solid #333;
                border-radius: 3px;
                content: "\00a0";
                display: inline-block;
                font: 16px/1em sans-serif;
                height: 22px;
                margin: 0 1em 0 0;
                padding: 0;
                vertical-align: top;
                width: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
            }
            input[type="checkbox"]:checked + label:after {
                background: #5142fc;
                border-color: #5142fc;
                color: #fff;
                content: "\2713";
                text-align: center;
            }
            input[type="checkbox"]:checked + label:after {
                font-weight: bold;
            }
            
            input[type="checkbox"]:focus + label::before {
                outline: rgb(59, 153, 252) auto 5px;
            }
            
          }

          
    }
}
