@import "../../global.scss";
.accordion-header svg {
    margin-right: 10px;
  }
  .accordion-item {
    background-color: transparent;
    color: white;
    text-align: left;
  }
  .accordion-button {
    background-color: #343444 !important;
    color: white;
    border-radius: 8px;
  }
  .properties-stats-levels-container {
    display: flex;
    /* grid-template-columns: 25% 25% 25% 25%; */
    grid-gap: 20px;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
  }
  .properties-stats-levels span:first-child {
    color: #6f87e7;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .properties-stats-levels-span-last {
    color: rgb(138, 147, 155);
    font-size: 13px;
    line-height: 16px;
    min-height: 16px;
  }
  .properties-stats-levels span:nth(2)-child,
  .properties-stats-levels-span {
    color: rgb(229, 232, 235);
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .properties-stats-levels {
    width: fit-content;
    padding: 10px 20px;
    width: 30% !important;
    margin-right: 0px !important;
    box-sizing: border-box;
    // background-color: rgba(21, 178, 229, 0.06);
    background-color: #343444;
    border-radius: 15px;
    // border: 1px solid rgb(21, 178, 229);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include mobile_potrait {
      width: 80% !important;
  }
  }
  .no-levels-yet {
    display: flex;
    flex-direction: column;
  
    justify-content: center;
    align-items: center;
  }
  .no-levels-yet svg {
    width: 100px;
    margin-top: 10px;
    height: 74px;
    fill: white;
    margin-bottom: 10px;
    color: white !important;
    fill: white !important;
  }
  .LvlPropStat-Tables {
    border-radius: 4px;
    padding-right: 2px;
    height: 200px !important;
    width: 100%;
    margin: auto;
  }
  .LvlPropStat-Tables .each-traded-item {
    padding: 10px !important;
  }
  
  .LvlPropStat-Tables span {
    font-size: 20px;
  }
  @media only screen and (max-width: 1440px) {
    .LvlPropStat-Tables span {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 990px) {
    .what-so-ever {
      max-width: 750px;
      margin: 0 auto;
      width: 100%;
    }
    .LvlPropStat-Tables {
      width: 820px;
    }
  }
  