@import "../../../global.scss";
.historyCard{
    width: 100%;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    transition: all 0.3s ease;
    box-shadow: 5px 5px 10px #00000022;
    border-bottom: 1px solid #333;
    
    .avatar{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        img{
            width: 48px;
            height: 48px;
            border-radius: 10px;
        }
        span{
            font-size: 0.8rem;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            a{
                text-decoration: none;
                color: #fff;
                font-weight: 600;
                font-size: 14px;
            }
            p{
                margin: 0rem;
                font-size: 14px;
            }
        }
    }
    .price{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h6{
            margin: 0;
            font-size: 14px;
        }
        p{
            margin: 0;
            font-size: 14px;
        }
    }
}