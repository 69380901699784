@import "../../global.scss";

.explorer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 3rem !important;
    padding-bottom: 2rem;

    @include mobile_potrait {
        flex-direction: column;
        padding-left: 0rem;
        padding-right: 0rem;
        background-size: cover;
    }

    .status {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        ul {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;

            @include mobile_potrait {
                width: 100%;
                flex-wrap: wrap;
            }

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 5px 20px;
                margin: 10px;
                border-radius: 10px;
                background-color: #343444;
                min-width: 7rem;

                h5 {
                    margin: 0;

                    img {
                        height: 1rem;
                    }
                }

                p {
                    margin: 0;

                }
            }
        }
    }

    .filterDiv {
        max-width: 350px;
        width: 20vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @include mobile_potrait {
            // position: absolute;
            width: 100%;
            max-width: 100%;
        }

    }

    .container-div1 {
        width: 90vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .row {
            width: 100%;

        }

        @include mobile_potrait {
            width: 100%;
            margin-top: 2rem;
        }
    }

    .collection-approve {
        position: absolute;
        max-height: 40px;
        right: 20px;
        top: 0px;
        width: 120px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: min(16px, 1vw);
        padding: 5px 30px;
        border: 1px #fff solid;
        background-color: #ffffff00;
        color: #fff;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.3s ease;

        &:hover {
            background-color: #ffffff33;
        }

        @include mobile_potrait {
            font-size: 14px;
            padding: 7px 20px;
            width: 100%;
        }
    }

    .container-div0 {
        width: calc(100% - 350px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .row {
            width: 100%;

        }

        @include mobile_potrait {
            width: 100%;
            margin-top: 2rem;
        }

        .csvBtn {
            max-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: min(16px, 1vw);
            padding: 5px 30px;
            border: 1px #fff solid;
            background-color: #ffffff00;
            color: #fff;
            cursor: pointer;
            border-radius: 5px;
            transition: all 0.3s ease;

            &:hover {
                background-color: #ffffff33;
            }

            @include mobile_potrait {
                font-size: 14px;
                padding: 7px 20px;
                width: 100%;
            }
        }
    }

    .nftShow1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        width: 90vw;

        @include mobile_potrait {
            justify-content: center;

            width: 90%;
        }

        .nftList {
            width: fit-content;
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: row;
            transition: all 0.3s ease;

            @include mobile_potrait {
                // flex-direction: column;
                width: 90%;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
            }
        }
    }

    .nftShow0 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        max-width: 1440px;
        width: calc(calc(90vw - 4rem - 30px) - 200px) !important;

        @include mobile_potrait {
            justify-content: center;
            width: 100% !important;
        }

        .nftList1 {
            width: 100%;
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            min-height: 60vh;

            @include mobile_potrait {
                width: 100%;
                align-items: center;
                justify-content: center;
            }
        }



    }

    .pagenation {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        @include mobile_potrait {
            display: none;
        }

        .dot {
            width: 10px;
            height: 10px;
            border: 1px #fff solid;
            margin: 10px;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;

            &::after {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                border: 1px #ffffff00 solid;
                border-radius: 50%;
                transition: all 0.3s ease;
            }
        }

        .activeDot {
            background-color: #fff;

            &::after {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                border: 1px #ffffff solid;
                border-radius: 50%;
            }
        }

        .prevBtn {
            margin-right: 0.5rem;
            color: #fff;
            transition: all 0.3s ease;
            cursor: pointer;

            &:hover {
                color: #555;
            }
        }

        .nextBtn {
            margin-left: 0.5rem;
            color: #fff;
            transition: all 0.3s ease;
            cursor: pointer;

            &:hover {
                color: #555;
            }
        }
    }

    .pagenation1 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .prevBtn {
            margin-right: 0.5rem;
            color: #fff;
            transition: all 0.3s ease;
            cursor: pointer;

            &:hover {
                color: #555;
            }
        }

        .page {
            margin: 0.5rem;

            span {
                padding: 0.5rem;
                cursor: pointer;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
                border-radius: 5px;
                transition: all 0.3s ease;

                &:hover {
                    color: #fff;
                }
            }

            .activePage {
                background-color: #fff;

                &:hover {
                    color: #fff;
                    background-color: #aaa;
                }
            }
        }

        .nextBtn {
            margin-left: 0.5rem;
            color: #fff;
            transition: all 0.3s ease;
            cursor: pointer;

            &:hover {
                color: #555;
            }
        }
    }

}

.detail-top {
    padding-bottom: 1rem;
    height: auto;

    @include mobile_potrait {}
}