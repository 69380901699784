

.line{
    width: 100%;
    height: 1px;
    

}
.c-w{
    background-image: linear-gradient(to right, #ffffff00, #ffffff, #ffffff00);
}
.c-b{
    background-image: linear-gradient(to right, #00000000, #000000, #00000000);
}
.loading-div{
    width: 100%;
    min-height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
}