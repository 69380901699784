@import "../../global.scss";
.btnTimer{
    min-width: 6rem;
    @include mobile_potrait{
        min-width: 4rem;
        height: auto;
        width: 4.7rem;
    }
    @include mobile_landscape{
        height: 2rem;
        min-width: 4.5rem;
    }

    @include mobile_potrait{
        height: auto !important;
    }
    @include mobile_landscape{
        height: auto !important;
    }
    .timerNums{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgb(201, 201, 201);
        font-size: 1rem;
        padding: 0rem 0.2rem;
        @include mobile_potrait{
            font-size: 1rem;
            height: 1rem !important;
        }
        @include mobile_landscape{
            font-size: 1rem;
            height: 1rem !important;
        }
        .number{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 1.2rem !important;
            font-weight: normal;
            font-family: Great_Fighter; 
            @include mobile_potrait{
                font-size: 0.8rem!important;
                height: auto !important;
            }
            @include mobile_landscape{
                font-size: 0.8rem!important;
                height: auto !important;
            }

        }
        .txt{
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgb(255, 255, 255);
            font-size: 0.8rem !important;
            font-weight: normal;
            margin-right: 0.2rem;
            font-family: Great_Fighter; 
            @include mobile_potrait{
                font-size: 0.5rem!important;
                margin-right: 0.2rem;
            }
            @include mobile_landscape{
                font-size: 0.5rem!important;
                margin-right: 0.2rem;
            }
        }
    }
}
.hiddenTimer{
    width: 0%;
}
.PreTimer{
    width: 100%;
    .PreTimerNums{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .number{
            width: 90%;
            height: 70%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: rgb(0, 0, 0);
            font-size: 1rem;
            margin: 1rem;
            
            @include mobile_potrait{
                margin: 0.2rem;
            }
            @include mobile_landscape{
                margin: 0.2rem;
            }
            .nums{
                color: white;
                font-size: 3.5rem;
                line-height: 4rem;
                font-family: 'Roboto', sans-serif;
                width: 5rem;
                height: 5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 5px #50FCC2 solid;
                border-radius: 50%;
                margin-bottom: 1rem;
                @include mobile_potrait{
                    font-size: 1.5rem;
                    line-height: 2rem;
                    width: 3rem;
                    height: 3rem;
                }
                @include mobile_landscape{
                    font-size:1.5rem;
                    line-height: 2rem;
                    width: 3rem;
                    height: 3rem;
                }
            }
            span{
                color: white;
                font-size: 1.5rem;
                font-family: 'Roboto', sans-serif;
                @include mobile_potrait{
                    font-size: 1rem;
                }
                @include mobile_landscape{
                    font-size: 0.8rem;
                }
            }
        }
    }
}