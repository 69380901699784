@import "../../global.scss";
.topSellerOne{
    width: 100%;
    padding-left: 2rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    .container-div{
        width: 90vw;
    }
    .row{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        @include mobile_potrait{
            width: 100%;
        }
        .sellerList{
            width: fit-content;
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: row;
            transition: all 0.3s ease;
            @include mobile_potrait{
               align-items: center;
               flex-wrap: wrap;
               width: 100%;
            }
        }
        .intro-content{
            width: 100%;
            .header{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row;

            }
            .pagenation{
                display: flex;
                align-items: center;
                justify-content: center;
                @include mobile_potrait{
                    display: none;
                 }
                .prevBtn{
                    color: #fff;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    border: 1px #5142fc solid;
                    margin-right: 0.5rem;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    &:hover{
                        color: #5142fc;
                    }
                }
                .nextBtn{
                    color: #fff;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    border: 1px #5142fc solid;
                    margin-left: 0.5rem;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    &:hover{
                        color: #5142fc;
                    }
                }
            }
        }
        
    }
    
}