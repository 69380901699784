@import "../../global.scss";
.myProfile{
    width: calc(100%);
    padding: 0;
    // padding-top: 6rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 0;
    @include mobile_potrait{
        width: calc(100%);
        margin: 0 !important;
    }
    .row{
        @include mobile_potrait{
            width: 100% !important;
        }
    }
    .top{
        width: 100%;
    }
    .top-div{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: row !important;
        height: 400px;
        position: relative;
        padding-top: 8rem;
        &::after{
            opacity: 0.7;
        }
        @include mobile_potrait{
            flex-direction: column !important;
            height: auto;
            align-items: flex-start;
        }
        .avatar-div{
            width: 250px;
            height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: #d2c9c0;
            // border-radius: 10px;
            // box-shadow: 5px 5px 5px #00000033;
            @include mobile_potrait{
                width: 150px;
                height: 150px;
            }
        }
        .profile-div{
            width:100%;
            height:100%
        }
        .user-info{
            width: calc(100% - 300px);
            margin-left: 50px;
            position: relative;
            @include mobile_potrait{
                width: calc(100%);
                margin-left: 0px;
            }
            .card-body{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                padding-top: 0;
                @include mobile_potrait{
                    padding-top: 0;
                }
                .input-group{
                    width: 300px;
                    margin-bottom: 1rem;
                    p{
                        background-color: #d2c9c0;
                        color: #333;
                        border: none

                    };
                    button{
                        color: #333;
                        transition: all 0.3s ease;
                        &:hover{
                            color: #5142fc;
                        }
                    }
                    
                }
                .editBtn{
                    min-width: 10rem;
                }
            }
            
        }
        .social-links{
            position: absolute;
            top: 0;
            right : 70%;
            @include mobile_potrait{
               
               top: -10rem;
               right: 0;
               .social-icons{
                flex-direction: column !important;
               }
            }
            a{
                background-color: #343444;
                font-size: 1.2rem;
            }
        }
    }
}