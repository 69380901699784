@import "../../global.scss";
.createItem{
    width: calc(100% - 4rem);
    padding-left: 2rem;
    padding-bottom: 2rem;
    padding-top: 3rem;
    @include mobile_potrait{
        width: calc(100%);
        padding: 10px;
        padding-top: 2rem;
        margin: 0 !important;
    }
    .container-div{
        width: 100%;

        .form-group{
            @include mobile_potrait{
                padding: 0px !important;
            } 
        }
        
    }
    .area-upload{
        width: 100%;
    }
    .preview{
        @include mobile_potrait{
            width: 100% !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            flex-direction: column;
        } 
        .nftCard1{
            @include mobile_potrait{
                width: 100% !important;
                display: flex;
                align-items: center !important;
                justify-content: center !important;
                .card-media{
                    height: calc(50vw) !important;
                }
            }
        }
    }
    .item-form{
        @include mobile_potrait{
            width: calc(100%);
            padding: 10px;
            padding-top: 2rem;
            margin: 0 !important;
        } 
    }
    .form-control{
        border: 1px #aaa solid !important;
        border-radius: 7px !important;
        background-color: #ffffff00 !important;
    }
    .modal-content{
        background-color: #343444;
        border-radius: 10px;
        .card-div{
            background-color: #343444;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .addMore{
                border: 2px #fff solid;
                background-color: #ffffff00;
                border-radius: 2rem;
                transition: all 0.3s ease;
                &:hover{
                    border-color: #aaa;
                    color: #aaa !important;
                }
            }
            .closeBtn{
                border: 2px #fff solid;
                background-color: #ffffff00;
                border-radius: 50%;
                transition: all 0.3s ease;
                width: 2rem;
                height: 2rem;
                &:hover{
                    border-color: #aaa;
                    color: #aaa !important;
                }
            }
        }
    }
}