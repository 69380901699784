@import "../../global.scss";
.item-details-area{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    .container-div{
        width: 90%;
    }
    .row{
        width: 100%;
    }
    .col-div{
        width: 100%;
        @include mobile_potrait {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .left{
            width: calc(45% - 20px);
            @include mobile_potrait {
                width: 100%;
            }
        }
        .right{
            width: calc(55% - 20px);
            @include mobile_potrait {
                width: 100%;
            }
            h3{
                @include mobile_potrait {
                   font-size: 1.5rem;
                }
            }
            .myDropdown{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;
                &:hover{
                    .myDropdown-menu{
                        display: flex;
                    }
                }
                .shareBtn{
                    border: 1px #fff solid;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    background-color: #ffffff00;
                    color: #fff;
                }
                .myDropdown-menu{
                    position: absolute;
                    top: 100%;
                    right: 0;
                    background-color: #09080d;
                    list-style: none;
                    padding: 5px 10px;
                    display: none;
                    border: 1px #fff solid;
                    border-radius: 7px;
                    button{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: row;
                        width: 10rem;
                        svg{
                            margin-left: 0.5rem;
                        }
                    }
                }
            }
        }
    }
    .card-div{
        width: calc(50% - 20px);
        margin: 10px;
        background-color: #343444;
        padding: 10px;
        border-radius: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
        @include mobile_potrait {
            width: 90%;
        }
        p{
            margin: 0rem;
            color: #aaa;
            font-size: 14px;
        }
        h6{
            margin: 0;
        }
        .owner-meta{
            span{
                
               
            }
        }
    }
    .tab-content{
        max-height: 30vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 3px;
            @include mobile_potrait {
                width: 2px;
            }
            @include mobile_landscape {
                height: 2px;
            }
        }
        &::-webkit-scrollbar-track {
            background: #555;
        }
        &::-webkit-scrollbar-thumb {
            background: #aaa;
            transition: all 0.3s ease;
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #aaa;
        }
    }
}