@import "../../global.scss";
.sideBar{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: -100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: all 1s ease;
    background-color: #09080d;
    backdrop-filter: blur(5px);
    z-index: 99999;
    top: 0rem;
    &.expandSide{
        left: 0 !important;
    }

    @include mobile_potrait{
    }
    .menu-div{
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        .modal-header{
            width: 100%;

        }
    }
    ul{
        margin-top: 1rem;
        padding: 0;
        list-style: none;
        font-weight: 400;
        color:black;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 0.8rem;
    }
    .sub-menu{
        margin-left: 1rem;
        a{
            padding: 0 !important;
            color: #aaa !important;
            img{
                margin-right: 0.5rem;
            }
            
        }
        svg{
            margin-right: 0.5rem;
        }
        li{
            color: #aaa;
        }
    }
} 
