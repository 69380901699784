@import "../../../global.scss";
.sellerCard{
    width: calc(18vw - 30px);
    min-width: 258px;
    padding: 10px;
    margin: 15px;
    background-color: #343444;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 20px;
    transition: all 0.3s ease;
    box-shadow: 5px 5px 10px #00000022;
    @include mobile_potrait{

    }
    &:hover{
        transform: translateY(-0.5rem);
        .card-media{
            .button-place-bid{
                bottom: 40%;
                opacity: 1;
            }
        }
    }
    .cardContent{
        width: 100%;
        max-width: 238px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .single-seller{
            width: 100%;
            a{
                width: 70px;
            }
        }
        .seller-info{
            width: calc(100% - 70px);
            a{
                width: 100%;
            }
        }
    }
    
}