@import "../../global.scss";
.editProfile{
    width: calc(100%);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 5rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 0;
    @include mobile_potrait{
        width: calc(100%);
        margin: 0 !important;
    }
    .row{
        @include mobile_potrait{
            width: 100% !important;
        }
    }
    .image-over{
        width: 100%;
        // background-color: #d2c9c0;
        padding-top: 1rem;
        padding-bottom: 1rem;
        img{
            width: 250px;
            height: 250px;
            object-fit: cover;
        }
    }
    .item-form{
        @include mobile_potrait{
            width: calc(100%);
            padding: 10px;
            padding-top: 2rem;
            margin: 0 !important;
        } 
    }
    .border-input{
        border: 1px #aaa solid !important;
        border-radius: 7px !important;
        background-color: #ffffff00 !important;
        overflow: hidden;
        &::after{
            background-color: #ffffff00 !important;
        }
    }
}