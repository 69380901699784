@import "../../global.scss";
.navbar{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-div{
    width: 90vw !important;
    max-width: 90vw !important;
}
.avatar-img{
    height: 1.5rem !important;
    width: 1.5rem !important;
    margin-right: 0.5rem;
    @include mobile_potrait {
        margin: 0 !important;
        min-width: 1.5rem !important;
    }
}
.wallet-btn{
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile_potrait {
        // padding: 0 !important;
    }
}