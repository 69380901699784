@import "../../../global.scss";
.fileContainer {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.246);
  height: 302px;
  padding: 10px;
  border-radius: 10px;
  @media screen and (max-width: 490px) {
    height: fit-content;
    padding: 0px;
    margin: 0 auto;
  }
  .internal-btn-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    @include mobile_potrait{
      padding: 0;
      width: 90%;
    }
    p {
      width: 65% !important;
      margin-bottom: 0 !important;
      margin-right: 20px !important;
      @media screen and (max-width: 490px) {
        width: 90% !important;
        margin-right: 0 !important;
        margin-bottom: 20px !important;
      }
    }
    @media screen and (max-width: 490px) {
      flex-direction: column;
    }
  }
  @include mobile_potrait {
    width: 90% !important;
    height: 200px;
  }
  @include mobile_landscape {
    height: 260px;
  }
  .rbFileInput {
    width: 100%;
    cursor: pointer;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 10px;

    justify-content: center;
    
    button{
      border: 2px #fff solid;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      padding: 0.8rem 2rem;
      background-color: #ffffff00;
      border-radius: 2rem;
      color: #fff;
      svg{
        width: 1rem;
        height: 1rem;
        margin-left: 0.5rem;
      }
      .fa {
        color: #0eabff;
        width: 1rem;
      }
    }
    .rbFile {
      width: 100%;
      height: 100%;
      padding: 4px;
      overflow: hidden;
      position: absolute;
      border-radius: 5px;
      &:hover {
        background-color: #222;
        z-index: 2;
        .video-change {
          color: #2178fa;
        }
      }

      .removeImg {
        top: 12px;
        color: #cccccc;
        right: 12px;
        position: absolute;
        font-size: 38px;
        line-height: 50%;
        transition: all 0.3s ease;
        z-index: 1;
        &:hover {
          color: #fff;
        }
      }
      img {
        width: 100%;
        height: 100%;
        opacity: 1;
        object-fit: cover;
        transition: opacity 400ms ease 0s;
        border-radius: 10px;
        &:hover {
          // opacity: 0.8;
        }
      }
      .video-change {
        color: white;
        height: 50px;
        font-size: 20px;
        text-align: center;
        line-height: 50px;
      }
    }
    p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      color: white;
      font-size: 16px;
      margin: 0 0 10px;
      max-width: 1280px;
      text-align: center;
    }
    .text-label {
      font-size: 18px;
      margin-top: 15px;
      font-weight: bold;
      .text-danger {
        color: #eb5757;
        margin: 0px 3px;
      }
    }
  }
  label {
    font-size: 14px;
    line-height: 1.42857;
    color: #aaaaaa;
    font-weight: 400;
  }
}
.w-h-160 {
  width: 160px;
  height: 160px;
}
