@import "../../global.scss";
.hero-section{
    position: relative;
    overflow: hidden;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .container{
        width: 80%;
        max-width: 80%;
        @include mobile_potrait{
            width: 90%;
            max-width: 90%;
        }
        .mainImg{
            display: flex;
            align-items: center;
            justify-content: center;
            perspective: 300px;
            .main{
                z-index: 1;
                width: 50vw;
                animation: cardAni3 10s ease-in-out 0s infinite;
                @include mobile_potrait{
                    width: 70vw;
                }
                filter: brightness(0.8);
                @include mobile_potrait{
                }
                @keyframes cardAni3 {
                    0% {
                        transform: rotateY(5deg) rotateX(0deg) translate3d(0px, 0px, 10px);
                    }
                    50% {
                        transform: rotateY(-5deg) rotateX(-5deg) translate3d(20px, 30px, -20px);
                    }
                    100% {
                        transform: rotateY(5deg) rotateX(0deg) translate3d(0px, 0px, 10px);
                    }
                }
            }
            .line1{
                position: absolute;
                width: 35vw;
                height: 35;
                animation: cardAni 10s ease-in-out 1s infinite;
                @include mobile_potrait{
                }
                @keyframes cardAni {
                    0% {
                        transform: rotateY(3deg) rotateX(0deg) translate3d(0px, 0px, 10px);
                    }
                    50% {
                        transform: rotateY(-3deg) rotateX(-3deg) translate3d(10px, 20px, -20px);
                    }
                    100% {
                        transform: rotateY(3deg) rotateX(0deg) translate3d(0px, 0px, 10px);
                    }
                }
            }
        }
        .button-group{
            @include mobile_potrait{
                display: flex;
                justify-content: space-between !important;
            }
        }
        .item{
            h1{
                @include mobile_potrait{
                    font-size: 2rem !important;
                    text-align: center;
                }
            }
            p{
                @include mobile_potrait{
                    font-size: 1rem !important;
                    margin-bottom: 10vh;
                    text-align: center;
                }
            }
        }
    }
       
    .overly{
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        .sectionBg{
            width: 100%;
        }
        .shape1{
            position: absolute;
            border-radius: 50%;
            background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
        }
        .shape2{
            position: absolute;
            border-radius: 50%;
            background: linear-gradient(216.56deg, #e250e5 5.32%, #4b50e6 94.32%);
            
        }
        .item-1{
            top: 24.8%;
            right: 36.8%;
            width: 16px;
            height: 16px;
            @include mobile_potrait{
                width: 8px;
                height: 8px;
            }

            animation: move3 10s infinite linear;
            @keyframes move3 {
                0% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                50% {
                    -webkit-transform: translate(30px);
                    transform: translate(30px);
                }
                100% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                
            }
        }
        .item-2{
            top: 60.5%;
            left: 35.5%;
            width: 22px;
            height: 22px;
            @include mobile_potrait{
                width: 12px;
                height: 12px;
            }
            animation: move4 10s infinite linear;
            @keyframes move4 {
                0% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                50% {
                    -webkit-transform: translate(-30px);
                    transform: translate(-30px);
                }
                100% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                
            }
        }
        .item-3{
            top: 56.7%;
            right: 18.8%;
            width: 32px;
            height: 32px;
            @include mobile_potrait{
                width: 16px;
                height: 16px;
            }
            animation: move3 10s infinite linear;
            @keyframes move3 {
                0% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                50% {
                    -webkit-transform: translate(30px);
                    transform: translate(30px);
                }
                100% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                
            }
        }
        .item-4{
            top: 53.5%;
            right: 39.6%;
            width: 48px;
            height: 48px;
            @include mobile_potrait{
                width: 24px;
                height: 24px;
            }
            animation: move4 10s infinite linear;
            @keyframes move4 {
                0% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                50% {
                    -webkit-transform: translate(-30px);
                    transform: translate(-30px);
                }
                100% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                
            }
        }
        .item-5{
            top: 14.5%;
            right: 16.4%;
            width: 51px;
            height: 51px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include mobile_potrait{
                width: 26px;
                height: 26px;
            }
            animation: move4 10s infinite linear;
            @keyframes move4 {
                0% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                50% {
                    -webkit-transform: translate(30px);
                    transform: translate(30px);
                }
                100% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                
            }
            &::before {
                content: "";
                width: 47px;
                height: 47px;
                background: #14141f;
                border-radius: 50%;
                @include mobile_potrait{
                    width: 24px;
                    height: 24px;
                }
            }
        }
        .item-6{
            top: 28%;
            right: 45.8%;
            width: 51px;
            height: 51px;
            animation: move4 10s infinite linear;
            display: flex;
            align-items: center;
            justify-content: center;
            @include mobile_potrait{
                width: 20px;
                height: 20px;
            }
            @keyframes move4 {
                0% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                50% {
                    -webkit-transform: translate(30px);
                    transform: translate(30px);
                }
                100% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                
            }
            &::before {
                content: "";
                width: 47px;
                height: 47px;
                background: #14141f;
                border-radius: 50%;
                @include mobile_potrait{
                    width: 18px;
                    height: 18px;
                }
            }
        }
        .item-7{
            top: 28%;
            right: 13.5%;
            width: 68px;
            height: 68px;
            animation: move4 10s infinite linear;
            @include mobile_potrait{
                width: 34px;
                height: 34px;
            }
            @keyframes move4 {
                0% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                50% {
                    -webkit-transform: translate(-30px);
                    transform: translate(-30px);
                }
                100% {
                    -webkit-transform: translate(0);
                    transform: translate(0);
                }
                
            }
        }
    }
    
}