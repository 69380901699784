@import "../../global.scss";
.activity{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 3rem;
    @include mobile_potrait{
        flex-direction: column !important;
        padding-left: 0rem;
        padding-right: 0rem;
    }
    .filter-div{
        width: 350px;
        width: 20vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
            // position: absolute;
            width: 100%;
        }
        
    }
    .container-div{
        // width: 90%;
        width: calc(100% - 400px);
        margin-left: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
            // position: absolute;
            width: 100%;
            margin-left: 0;
            margin-top: 2rem;
        }
        .row{
            width: 100%;
            display: flex;
            align-items: flex-start;
            // justify-content: center;
            flex-direction: row;
            @include mobile_potrait{
                flex-direction: column !important;
            }
            .csvBtn{
                max-height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: min(16px, 1vw);
                padding: 5px 30px;
                border: 1px #fff solid;
                background-color: #ffffff00;
                color: #fff;
                cursor: pointer;
                border-radius: 5px;
                transition: all 0.3s ease;
                &:hover{
                    background-color: #ffffff33;
                }
                @include mobile_potrait{
                    font-size: 14px;
                    padding: 7px 20px;
                    width: 100%;
                }
            }
        }
    }
    
    .active-content{
        // max-height: 70vh;
        width: 100%;
        
        // overflow-y: scroll;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        
        @include mobile_potrait{
            // position: absolute;
            width: 100%;
            margin-left: 0px;
        }
        &::-webkit-scrollbar {
            width: 3px;
            @include mobile_potrait {
                width: 2px;
            }
            @include mobile_landscape {
                height: 2px;
            }
        }
        &::-webkit-scrollbar-track {
            background: #555;
        }
        &::-webkit-scrollbar-thumb {
            background: #aaa;
            transition: all 0.3s ease;
            border-radius: 8px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #aaa;
        }
        ul{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            margin-bottom: 2rem;
            min-height: 60vh;
            @include mobile_potrait{
                width: 90%;
            }
            li{
                width: calc(100% - 40px);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                min-height: 2rem;
                p{
                    color: #fff;
                    font-size: 14px;
                    margin: 0;
                   
                }
                h6{
                    color: #fff;
                    margin: 0;
                    font-size: 16px;
                }
                div{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
                .col1{
                    width: 10%;
                    p{
                        margin-left: 0.5rem;
                       
                    }
                    @include mobile_potrait{
                        display: none !important;
                    }
                }
                .col2{
                    width: 30%;
                    @include mobile_potrait{
                        width: 50%;
                    }
                }
                .col3{
                    width: 10%;
                    @include mobile_potrait{
                        width: 20%;
                    }
                }
                .col4{
                    width: 10%;
                    @include mobile_potrait{
                        display: none !important;
                    }
                }
                .col5{
                    width: 15%;
                    @include mobile_potrait{
                        display: none !important;
                    }
                }
                .col6{
                    width: 15%;
                    @include mobile_potrait{
                        display: none !important;
                    }
                }
                .col7{
                    width: 10%;
                    @include mobile_potrait{
                        display: none !important;
                    }
                }
                .col8{
                    display: none;
                    @include mobile_potrait{
                        display: flex;
                        width: 30%;
                    }
                }
            }
        }
        .pagenation1{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            .prevBtn{
                margin-right: 0.5rem;
                color: #fff;
                transition: all 0.3s ease;
                cursor: pointer;
                &:hover{
                    color: #555;
                }
            }
            .page{
                margin: 0.5rem;
                span{
                    padding: 0.5rem;
                    cursor: pointer;
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;
                    border-radius: 5px;
                    transition: all 0.3s ease;
                    &:hover{
                        color: #fff;
                    }
                }
                .activePage{
                    background-color: #fff;
                    &:hover{
                        color: #fff;
                        background-color: #aaa;
                    }
                }
            }
            .nextBtn{
                margin-left: 0.5rem;
                color: #fff;
                transition: all 0.3s ease;
                cursor: pointer;
                &:hover{
                    color: #555;
                }
            }
        }
    }
}