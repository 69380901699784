@import "../../../global.scss";
.nftCard1{
    width: calc(18vw - 20px) !important;
    padding: 20px;
    background-color: #343444;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 20px;
    transition: all 0.3s ease;
    box-shadow: 5px 5px 10px #00000022;
    @include mobile_potrait{
        width: calc(40vw) !important;
        border-radius: 10px;
        margin: 5px;
    }
    &:hover{
        transform: translateY(-0.5rem);
        .card-media{
            .button-place-bid{
                bottom: 40%;
                opacity: 1;
            }
        }
    }
    .card-media{
        width: calc(18vw - 50px) !important;
        max-width: calc(18vw - 50px) !important;
        height: calc(18vw - 50px) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @include mobile_potrait{
            width: calc(40vw - 20px) !important;
            max-width: calc(40vw - 20px) !important;
            height: calc(40vw - 20px) !important;
        }
        .nftCollection{
            width: 100%;
            height: 100%;
            background-color: #d2c9c0;
            border-radius: 20px;
            overflow: hidden;
            @include mobile_potrait{
                border-radius: 10px;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                
            }

        }
        .heart{
            position: absolute;
            top: 1rem;
            right: 1rem;
            box-shadow: 0px 0px 2px #fff;
            padding: 5px 20px;
            font-size: 0.8rem;
            border-radius: 1rem;
            background-color: #000;
            color: #fff;
        }
        .featured-countdown{
            position: absolute;
            bottom: 1rem;
            background-color: #343444;
            padding: 5px 10px;
            border-radius: 1rem;
            .slogan{
                margin-right: 0.5rem;
                color: #5142fc;
            }
            span{
                color: #fff;
                font-size: 16px;
            }
            
        }
        .button-place-bid{
            position: absolute;
            bottom: 25%;
            opacity: 0;
            transition: all 0.5s ease;
            button{
                border: none;
                padding: 8px 20px;
                border-radius: 2rem;
                background-color: #fff;
                transition: all 0.3s ease;
                color: #5142fc;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover{
                    background-color: #5142fc;
                    color: #fff;
                    span{
                        color: #fff;
                    }
                }
                .fas{
                    color: #5142fc;
                    transition: all 0.3s ease;
                }
                span{
                    color: #5142fc;
                    transition: all 0.3s ease;
                    margin-left: 0.5rem;
                    @include mobile_potrait{
                        font-size: 10px;
                    }
                }
            }
            
        }
    }
    .card-title{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        // height: 7rem;
        margin-bottom: 0rem;

        h5{
            margin: 0.5rem;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            box-sizing: border-box;
            a{
                text-decoration: none;
                color: #fff;
                font-size: min(20px, 1vw);
                @include mobile_potrait{
                    font-size: 12px;
                }
            }
        }
        .desc{
            width: 100%;
        }
    }
    .meta-info{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        .author{
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .avatar{
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 50px;
                    height: 50px;
                    border-radius: 10px;
                }
            }
            .info{
                width: calc(100% - 80px);
                margin-left: 10px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                span{
                    margin-bottom: 5px;
                    font-size: 0.8rem;
                }
                h6{
                    margin: 0;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    box-sizing: border-box;
                    a{
                        text-decoration: none;
                        color: #fff;
                        font-size: min(20px, 0.8vw);
                        @include mobile_potrait{
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .coninType{
            background-color: #5142fc;
            padding: 5px 10px;
            border-radius: 1rem;
            p{
                color: #fff;
                font-size: 14px;
                font-size: 0.8rem;
                @include mobile_potrait{
                    font-size: 12px;
                }
            }
        }
    }
    .cardFoot{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        .price{
            width: 40%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            span{
                margin-bottom: 5px;
                font-size: min(0.8rem, 0.7vw);
                color: #aaa;
                @include mobile_potrait{
                    font-size: 12px;
                }
            }
            h5{
                margin: 0;
                text-decoration: none;
                color: #fff;
                font-size: min(16px, 1vw);
                display: flex;
                align-items: center;
                justify-content: center;
                @include mobile_potrait{
                    font-size: 12px;
                }
                img{
                    height: 1rem;
                    margin-right: 0.5rem;
                }
            }
        }
        .usd{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            color: #fff;
            font-size: 0.8rem;
            .fas{
                margin-right: 0.5rem;
            }
            p{
                margin: 0;
                margin-left: 0.5rem;
                color: #aaa;
                font-size: min(0.8rem, 0.7vw); 
                @include mobile_potrait{
                    font-size: 10px;
                }
            }
        }
    }
    
}