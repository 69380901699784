@import "../../../global.scss";
.leftFilter{
    width: calc(100%);
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-right: 1px #333 solid;
    min-height: 100vh;
    background-color: #343444;
    border-radius: 10px;
    @include mobile_potrait{
        min-height: auto;
        width: 90%;
    }
    .filterTop{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        border-bottom: 1px solid #333;
        h3{
            margin: 0;
            margin-bottom: 0.5rem;
            
        }
        .clear{
            border: none;
            background-color: #ffffff00;
            color: #5142fc;
            transition: all ease 0.3s;
            cursor: pointer;
            &:hover{
                color: #fff;
            }
        }

    }
    
    .searchFilter{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .searchHeader{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
        }
        .search {
            color: #ffffff;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            span {
                margin-bottom: 0.5rem;
                padding: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                input {
                    font-size: 14px;
                    border: 1px #aaa solid;
                    border-radius: 7px;
                }
                button{
                    position: absolute;
                    right: 0;
                    border: none;
                    background-color: #ffffff00;
                    color: #fff;

                }
            }
                
        }
    }
    .myform{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 1;
        margin-bottom: 1rem;
        
        @include mobile_potrait{
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
        }
        @include mobile_landscape{
        }
        
        span{
            color: #000;
            margin-bottom: 0.5rem;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
            @include mobile_potrait{
                margin-bottom: 1rem;
                width: 40%;
                margin-left: 10%;
                padding: 0;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: row;
            }
            @include mobile_landscape{
                margin-bottom: 0rem;
            }
            label{
                color: #fff;
                width: auto;
                @include mobile_potrait{
                    font-size: 0.8rem;
                    margin-left: 1rem;
                }
                @include mobile_landscape{
                    font-size: 0.8rem;
                }
            }
        }
          
        .radio {
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            margin-right: 4px;
            &:before {
                transition : all 0.4s ease;
                transform : scale(0,0); 
                content: "✓";
                position: absolute;
                z-index: 1;
                color: #fff;
            }
            
            &:checked {
                &:before {
                transform : scale(1,1); 
                
                }
                &::after{
                    background: #5142fc;
                }
            }
            
            &:after {
                content: "";
                position: absolute;
                top: -4px;
                left: -4px;
                transition : all 0.4s ease;
                width: 22px;
                height: 22px;
                background: #fff;
                border: 1px solid #333;
                border-radius: 3px;
            }
        }

    }
}
