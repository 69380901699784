@import "../../global.scss";
.myModal{
    .modal-content{
        background-color: #343444;
        border-radius: 10px;
        max-width: 30rem;
        .bakc{
            border: 2px #fff solid;
            background-color: #ffffff00;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;
                &:hover{
                    color: #aaa !important;
                    border-color: #aaa;
                }
        }
        .saleContainer{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            button{
                border-radius: 2rem;
                border: 2px #fff solid;
                background-color: #ffffff00;
                color: #fff;
                transition: all 0.3s ease;
                &:hover{
                    border-color: #aaa;
                    background-image: linear-gradient(135deg, var(--primary-color) 10%, var(--secondary-color));
                }
            }
            .input-group{
                border: 1px #aaa solid !important;
                border-radius: 7px !important;
                background-color: #ffffff00 !important;
                overflow: hidden;
                .input-group-text{
                    background-color: #ffffff00 !important;
                }
            }
            input{
                background-color: #ffffff00 !important;
                &:focus{
                    outline: none;
                }
            }
            .usd{
                margin-bottom: 1rem !important;
            }
            .detail{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
        .btns{
            button{
                border-radius: 2rem;
                border: 2px #fff solid;
                background-color: #ffffff00;
                color: #fff;
                transition: all 0.3s ease;
                &:hover{
                    border-color: #aaa;
                    
                }
            }
            .mind{
                &:hover{
                    color: #796dff ;

                }
            }
            .buy{
                &:hover{
                    background-image: linear-gradient(135deg, var(--primary-color) 10%, var(--secondary-color));
                }
            }
        }
        .transferInput{
            border: 1px #aaa solid !important;
            border-radius: 7px !important;
            background-color: #ffffff00 !important;
        }
        .title{
            color: #fff;
        }
    }
}