@import "../../global.scss";
.popular-collections-area{
    width: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    @include mobile_potrait{
        flex-direction: column;
        padding-left: 0rem;
        padding-right: 0rem;

    }
    .filterDiv{
        width: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
            // position: absolute;
            width: 100%;
        }
        
    }
    .container-div0{
        width: calc(100vw - 4rem - 350px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
            width: 100%;
            margin-top: 2rem;
        }
        .custom-selection{
            width: 100%;
            @include mobile_potrait{
               justify-content: flex-start !important;
    
            }
            .sort{
                @include mobile_potrait{
                    width:  fit-content !important;
                    max-width: fit-content !important;
                 }
            }
        }
    }
    .container-div1{
        width: 90vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile_potrait{
            width: 100%;

        }
        .custom-selection{
            @include mobile_potrait{
                justify-content: center !important;
            }
        }
    }
    .row{
        width: 100%;
    }
    .collections{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        @include mobile_potrait{
            width: 90%;
        }
        .collectionList{
            width: fit-content;
            height: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            transition: all 0.3s ease;
            @include mobile_potrait{
                flex-direction: column;
                width: 100%;
            }
        }
        .loading-div{
            width: 100%;
            
            min-height: 15rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .collectionList1{
            width: fit-content;
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            min-height: 60vh;
            @include mobile_potrait{
                flex-direction: column;
                
            }
        }

        .pagenation1{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            .prevBtn{
                margin-right: 0.5rem;
                color: #fff;
                transition: all 0.3s ease;
                cursor: pointer;
                &:hover{
                    color: #555;
                }
            }
            .page{
                margin: 0.5rem;
                span{
                    padding: 0.5rem;
                    cursor: pointer;
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;
                    border-radius: 5px;
                    transition: all 0.3s ease;
                    &:hover{
                        color: #fff;
                    }
                }
                .activePage{
                    background-color: #fff;
                    &:hover{
                        color: #fff;
                        background-color: #aaa;
                    }
                }
            }
            .nextBtn{
                margin-left: 0.5rem;
                color: #fff;
                transition: all 0.3s ease;
                cursor: pointer;
                &:hover{
                    color: #555;
                }
            }
        }
        .pagenation{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            @include mobile_potrait{
                display: none;
            }
            .dot{
                width: 10px;
                height: 10px;
                border: 1px #fff solid;
                margin: 10px;
                border-radius: 50%;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease;
                &::after{
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border: 1px #ffffff00 solid;
                    border-radius: 50%;
                    transition: all 0.3s ease;
                }
            }
            .activeDot{
                background-color: #fff;
                &::after{
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border: 1px #ffffff solid;
                    border-radius: 50%;
                }
            }
            .prevBtn{
                margin-right: 0.5rem;
                color: #fff;
                transition: all 0.3s ease;
                cursor: pointer;
                &:hover{
                    color: #555;
                }
            }
            .nextBtn{
                margin-left: 0.5rem;
                color: #fff;
                transition: all 0.3s ease;
                cursor: pointer;
                &:hover{
                    color: #555;
                }
            }
        }
    }
    
}