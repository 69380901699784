@import "../../global.scss";
.menu-div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ul{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column !important;
    }

}