@import "../../../global.scss";
.activityCard{
    width: calc(100% - 20px);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    transition: all 0.3s ease;
    background-color: #343444;
    // border-radius: 15px;
    border-bottom: 1px solid #444;
    margin: 0;
    @include mobile_potrait{
        width: 100% !important;
    }
    cursor: pointer;
    &:hover{
        box-shadow: 0px 0px 5px #00000088;
        transform: translateY(-1px);
    }
    .col1{
        
    }
    .col2{
        .avatar-lg{
            width: 60px;
            height: 60px;
            border-radius: 10px !important;
        }
        .activity-content{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            a{
                font-size: 12px;
                color: #fff;
                h5{
                    font-size: 12px;
                    color: #aaa;
                }
            }
        }
    }
    .col5{
        p{
            color: #89c6f9 !important;
        }
        @include mobile_potrait{
            display: none !important;
        }
    }
    .col3{
        img{
            height: 1rem;
        }
    }
    .col6{
        p{
            color: #89c6f9 !important;
        }
        @include mobile_potrait{
            display: none !important;
        }
    }
    
}